import Vue from 'vue';
import Search from './Search.vue';
Vue.config.productionTip = false;

import './style.css'

window.neQst = window.neQst || {}
window.neQst.initRentalSearch = (params) => {
  return new Vue({
    render: h => h(Search, {
      props: {...params.props}
    })
  }).$mount(params.node || '#neqst-rental-search')
}
