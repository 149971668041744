<template>
  <div class="flex items-center flex-wrap bg-white relative py-8 px-8 rounded-md">
    <div class="md:pr-3 w-full sm:w-full xl:w-2/5 mb-4 md:mb-0">
      <span class="block text-gray-600 text-sm text-left font-bold mb-2">Zeitraum wählen</span>
      <date-picker v-model="form.range" :masks="masks" mode="date" :model-config="dateModelConfig" is-range :min-date="new Date()">
        <template v-slot="{ inputValue, inputEvents, isDragging }">
          <div class="flex flex-col sm:flex-row justify-start items-center">
            <div class="relative flex-grow w-full">
              <svg
                class="text-gray-600 w-5 h-full mx-4 absolute pointer-events-none"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
              <input
                class="
                  text-base box-border border-solid flex-grow pl-10 pr-2 py-3.5 bg-gray-100 border rounded w-full outline-none
                  focus:border-primary focus:outline-none
                "
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                v-on="inputEvents.start"
                readonly
              />
            </div>
            <span class="flex-shrink-0 m-2">
              <svg class="w-5 h-5 stroke-current text-gray-600" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </span>
            <div class="relative flex-grow w-full">
              <svg
                class="text-gray-600 w-5 h-full mx-4 absolute pointer-events-none"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
              </svg>
              <input
                class="
                  text-base box-border border-solid flex-grow pl-10 pr-2 py-3.5 bg-gray-100 border rounded w-full outline-none
                  focus:border-primary focus:outline-none
                "
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.end"
                v-on="inputEvents.end"
                readonly
              />
            </div>
          </div>
        </template>
      </date-picker>
    </div>
    <div class="w-full md:w-1/5 xl:w-48 xl:px-3 pl-0 mb-4 md:mb-0">
      <span class="block text-gray-600 text-sm text-left font-bold mb-2">Anzahl Personen</span>
      <input
        v-model="form.persons"
        type="number"
        min="0"
        max="6"
        class="
          text-base box-border border-solid border text-gray-600 outline-none pr-2 py-3.5
          focus:border-primary focus:outline-none
          transition-colors ease-in-out duration-100 pl-3 bg-gray-100 w-full rounded
        "
      />
    </div>
    <div class="w-full md:w-1/5 xl:w-48 md:px-3">
      <span class="block text-gray-600 text-sm text-left font-bold mb-2">Standort</span>
      <div class="relative">
      <select
        v-model="form.location"
        class="
          block appearance-none leading-tight text-base box-border border-solid border text-gray-600 outline-none pr-2 py-4
          focus:border-primary focus:outline-none
          transition-colors ease-in-out duration-100 pl-3 bg-gray-100 w-full rounded
          disabled:opacity-50 disabled:cursor-not-allowed
        "
      >
        <option value="" disabled>Bitte wählen</option>
        <option value="rheine">Rheine</option>
        <option value="cappeln">Cappeln</option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </div>
      </div>
    </div>
    <div class="xl:pl-3 mt-7 flex-1">
      <a
        :href="`/vermietung/?startDate=${form.range.start}&endDate=${form.range.end}&persons=${form.persons}&location=${form.location}`"
        class="btn btn-primary rounded py-4 px-6 text-center block leading-tight border border-primary text-lg"
        >Suchen</a
      >
    </div>
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  props: {
    token: {
      required: true,
    },
  },
  components: { DatePicker },
  data() {
    const startDate = new Date()
    const endDate = new Date(Date.now()+6*24*60*60*1000)
    return {
      masks: {
        input: 'DD.MM.YYYY',
      },
      form: {
        range: {
          start: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`,
          end: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
        },
        persons: 2,
        location: '',
      },
      dateModelConfig: {
        start: {
          type: 'string',
          mask: 'YYYY-MM-DD',
        },
        end: {
          type: 'string',
          mask: 'YYYY-MM-DD',
        },
      },
    }
  },
  mounted() {},
}
</script>
<style lang="css" scoped>
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
  transform: scale(1);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  min-width: 10px;
  max-width: 10px;
}
</style>
